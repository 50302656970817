<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 222.5 26.1"
  >
    <path style="stroke-width: 0; fill: none" d="M0-31.7h222.5v68H0z" />
    <g>
      <path
        fill="#1d1d1b"
        d="M110.4 4.7c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3.6-1.3 1.3-1.3 1.3.6 1.3 1.3Zm-.4 15h-1.9V8.8h1.9v10.9ZM115.5 10c.6-.9 1.5-1.5 2.6-1.5s2.4.7 3 1.8c.7-1.2 1.9-1.8 3.3-1.8 2.8 0 3.5 2.3 3.5 4.6v6.6H126v-6.3c0-1.4 0-3.2-2-3.2s-2.4 2.1-2.4 3.7v5.8h-1.9v-6.2c0-1.3-.2-3.3-1.9-3.3s-2.4 2.2-2.4 3.8v5.6h-1.9V8.7h1.9v1.2ZM133.1 10c.6-.9 1.5-1.5 2.6-1.5s2.4.7 3 1.8c.7-1.2 1.9-1.8 3.3-1.8 2.8 0 3.5 2.3 3.5 4.6v6.6h-1.8v-6.3c0-1.4 0-3.2-2-3.2s-2.4 2.1-2.4 3.7v5.8h-1.9v-6.2c0-1.3-.2-3.3-1.9-3.3s-2.4 2.2-2.4 3.8v5.6h-1.9V8.7h1.9v1.2ZM147.8 14.3c0-3.2 2.5-5.8 5.7-5.8s5.7 2.6 5.7 5.8-2.6 5.7-5.7 5.7-5.7-2.5-5.7-5.7Zm1.9 0c0 2.2 1.6 4 3.9 4s3.9-1.8 3.9-4-1.6-4-3.9-4-3.9 1.8-3.9 4ZM161.5.6h1.9v9.8c.9-1.2 2.2-1.9 3.7-1.9 3.2 0 5.2 2.7 5.2 5.7s-2 5.8-5.2 5.8-2.9-.6-3.8-1.8v1.5h-1.9V.6Zm1.8 13.6c0 2.1 1.2 4.1 3.6 4.1s3.6-1.9 3.6-4.1-1.4-3.9-3.7-3.9-3.5 1.9-3.5 3.9ZM177.3 4.7c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3.6-1.3 1.3-1.3 1.3.6 1.3 1.3Zm-.4 15H175V8.8h1.9v10.9ZM182.4 19.7h-1.9V.6h1.9v19.1ZM188.2 4.7c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3.6-1.3 1.3-1.3 1.3.6 1.3 1.3Zm-.4 15H186V8.8h1.8v10.9ZM192.4 14.7c0 2 1.5 3.6 3.6 3.6s2.6-.9 3.3-2.2l1.6.9c-1 1.9-2.8 3.1-5 3.1-3.2 0-5.3-2.5-5.3-5.6s1.9-5.9 5.3-5.9 5.4 2.9 5.2 6.1h-8.6Zm6.6-1.6c-.3-1.6-1.6-2.9-3.2-2.9s-3.1 1.3-3.3 2.9h6.5ZM205.1 10.1c.8-1 1.8-1.6 3.1-1.6 3 0 3.7 2.2 3.7 4.7v6.5H210v-6.1c0-1.8-.1-3.3-2.3-3.3s-2.7 2.4-2.7 4.3v5.2h-1.9V8.9h1.9v1.3Z"
      />
    </g>
    <g>
      <path
        d="M43.2 14c0 3.4-2.7 6.1-6.1 6.1S31 17.4 31 14s2.7-6.1 6.1-6.1 6.1 2.7 6.1 6.1Z"
        style="fill: #e30613; stroke-width: 0"
      />
      <path
        fill="#1d1d1b"
        d="m16.9 14.8 2.3-6.4h3.5L17.5 20h-1.7L10.6 8.4h3.6l2.7 6.4ZM58.1 17.2c-1.7 0-2.7-1.6-2.7-3.2s1-3.2 2.7-3.2 2.7 1.7 2.7 3.2-1 3.2-2.7 3.2Zm5.6-8.9h-3.2v1.1c-.7-.9-1.8-1.5-2.9-1.5-3.4 0-5.3 3-5.3 6.1s1.8 6 5.1 6 2.4-.6 3.1-1.5v.7c0 1.7 0 3.7-2.3 3.7s-2.1-.8-2.2-2h-3.4c.2 3 3 4.7 5.7 4.7 4.3 0 5.5-3.1 5.5-6.8V8.1ZM77.1 12.5c.2-1.2.9-2.3 2.2-2.3s2 1.1 2.2 2.3h-4.3Zm7.4 2v-.4c0-3.4-1.7-6.2-5.4-6.2s-5.5 2.7-5.5 6.2 2.2 6 5.7 6 4.4-1.3 5.2-3.5l-3-.5c-.4.9-1.1 1.6-2.2 1.6-1.7 0-2.2-1.6-2.2-3.1h7.5ZM97.5 19.7h-3.2V0h3.2v19.7Z"
      />
    </g>
  </svg>
</template>
